@import '../../../../../ui-flanders/themes/bl/scss/abstract/mixins';
@import '../../../../../ui-flanders/themes/bl/scss/abstract/variables';

footer {
  .container {
    padding: 0 30px;
    margin: 0 auto;
    width: 100%;
    max-width: 1140px;
  }

  .spacing {
    box-sizing: border-box;
    padding: 7.5px 15px 7.5px 0;
  }

  .footer--top {
    background-color: $vl-accent-color;
    padding-top: 1.8rem;
    padding-bottom: 3.8rem;

    @include breakpoint(small) {
      padding-top: 3.8rem;
    }

    &_wrapper {
      align-items: flex-end;

      @include breakpoint(medium) {
        align-items: center;
      }
    }

    &_list {
      width: 100%;

      li {
        margin-top: 20px;
      }

      .vl-button {
        color: $vl-accent-color;
        background-color: white;
        min-width: 250px;
        justify-content: center;
        width: 100%;

        @include breakpoint(small) {
          width: auto;
        }

        &--secondary {
          color: white;
          background-color: transparent;
          border-color: white;
        }
      }

      @include breakpoint(small) {
        width: 50%;

        li {
          text-align: right;
        }
      }

      @include breakpoint(medium) {
        display: flex;
        width: auto;

        li {
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }
  }

  .site-logo {
    @include breakpoint(small) {
      max-width: 40%;
    }

    @include breakpoint(medium) {
      margin-right: 6.2rem;
    }

    &__link {
      display: block;
    }
  }

  .footer--bottom {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .copyright {
    color: black;
    font-size: 1.3rem;

    @include breakpoint(small) {
      display: inline-flex;

      &::after {
        content: '-';
        padding: 0 0.3rem;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(small) {
      display: inline-flex;
    }

    &__wrapper {
      width: 100%;
      flex-grow: 1;

      @include breakpoint(medium) {
        width: auto;
        flex-grow: 0;
      }
    }

    &__item {
      display: block;
      width: 100%;
      color: black;
      font-size: 1.3rem;

      a {
        display: block;
        padding: 10px 0;

        @include breakpoint(small) {
          display: inline-block;
          padding: 0;
        }
      }

      @include breakpoint(small) {
        display: inline-block;
        width: auto;

        &:not(:last-child)::after {
          content: '-';
          padding: 0 0.3rem;
        }
      }
    }

    &.lang-switch {
      @include breakpoint(small) {
        display: flex;
      }

      .list__item {
        display: inline-block;
        width: auto;

        &:not(:last-child)::after {
          content: '-';
          padding: 0 0.3rem;
        }
      }
    }

    &__link {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border-bottom: 1px solid transparent;
      color: black;
      text-decoration: none;
      transition: border-bottom-color 0.2s;

      &:hover,
      &:focus {
        border-bottom-color: black;
      }

      &.current-language {
        font-weight: 600;
        color: $vl-accent-color;
      }
    }
  }
}
