.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background: $vl-accent-color;
  box-shadow: none;
  color: $vl-white;
  border-color: $vl-accent-color;
}

.flatpickr-day:hover,
.flatpickr-day:focus,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  color: $vl-accent-color !important;
  background: #e8ebee !important;
  border-color: transparent !important;
  font-weight: 500;
}
