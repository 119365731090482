.vl-discussionItem {
  position: relative;
  border-radius: 0.4rem 0.4rem 0 0.4rem;
  background-color: #e9f3f8 !important;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 16px;
    height: 29px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 29.3'%3E%3Cpath d='M0,0V.6C.7,19.7,6,29.3,16,29.3H0Z' fill='%23e9f3f8'/%3E%3C/svg%3E");
  }

  .vl-push--reset & {
    border-radius: 0.4rem 0.4rem 0.4rem 0;
    background-color: $vl-alt-bg !important;

    &::before {
      left: -16px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 29.3'%3E%3Cpath d='M0,0V.6C.7,19.7,6,29.3,16,29.3H0Z' fill='%23f5f5f5'/%3E%3C/svg%3E");
      transform: scaleX(-1);
    }
  }

  .vl-push--3-12 & {
    &::before {
      right: -16px;
    }
  }
}
