$vl-base-font: 'Source Sans Pro', sans-serif;
$vl-accent-font: 'Source Sans Pro', sans-serif;

$input-border-radius: 4px;

// Height of the site-bar
$site-bar-height: 4.5rem;
// Breakpoints for navigation only
$vl-bp-medLarge: 1200px;

/* COLORS */

$vl-white: #fff;
$vl-black: #000;
$vl-highlighted-color: #01cbb0; // used in profile - ContractList

// override colors from vl-ui-core/src/scss-fresh/setting/_colors.scss
$vl-accent-color: #2f3e8b;
/*
 Note that they already use $vl-action-color for CTA
 But we added a new $vl-highlighted-action-color put the accent on some links
*/
$vl-action-color: $vl-accent-color;
$vl-action-hover-color: #6c7cd0;
$vl-highlighted-action-color: #006ab1;

$vl-error-color: #db3434;
$vl-error-secondary-color: #ff000019;
$vl-warning-color: #fee600;
$vl-warning-secondary-color: #fefce5;
$vl-success-color: #6eb400;
$vl-success-secondary-color: #f0f7e5;

$vl-ghost: #f4f5f7;
$vl-alt-bg: #f5f5f5; // alert
$vl-alt-region-bg: #f5f5f5;

$vl-border-color: #8d8d8d;
$vl-disabled-bg-color: #e5e5e5; // used for input
$vl-disabled-button-bg-color: #a2a2a2; // new for Bl

$vl-ghost-nav-bg: #f4f6f7;
$vl-ghost-nav-color: #5f727f;
$vl-ghost-nav-border: #dfe5e8;

$vl-outline-color: #2d87c3;

/// Breakpoints map to use in mixins respons
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'xsmall': 500px,
  'small': 767px,
  'medium': 1023px,
  'large': 1600px,
) !default;
