.vl-input-field,
.vl-textarea,
.vl-select {
  border-radius: $input-border-radius;
}

.vl-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M0.42,9.77a3.27,3.27,0,0,1,.91-2.21,3.12,3.12,0,0,1,4.41,0L16,17.82,26.25,7.56A3.12,3.12,0,0,1,30.67,12L18.2,24.44a3.12,3.12,0,0,1-4.41,0L1.33,12A3.17,3.17,0,0,1,.42,9.77' fill='%232d87c3'/%3e%3c/svg%3e");
}

.vl-datepicker {
  .vl-input-field {
    border-radius: $input-border-radius 0 0 $input-border-radius;
  }

  .vl-input-addon {
    border-radius: 0 $input-border-radius $input-border-radius 0;
  }

  .vl-vi-calendar {
    font-size: 2.2rem;
    vertical-align: baseline;
    color: $vl-highlighted-action-color !important;
  }

  .vl-input-field--disabled + .vl-datepicker__input-addon {
    background-color: $vl-disabled-bg-color;
  }
}

.vl-checkbox__label .vl-checkbox__box {
  &::before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath d='M4 13.5a1.67 1.67 0 01-1.34-.67L.6 10.1l.8-.6 2.05 2.73a.69.69 0 00.55.27.66.66 0 00.54-.29L12.59.71l.82.58-8 11.49A1.69 1.69 0 014 13.5z' fill='%23fff' /%3E%3C/svg%3E");
    width: 14px;
    height: 14px;
  }
}

.vl-radio__toggle:checked + .vl-radio__label::after,
.vl-checkbox__toggle:checked + .vl-checkbox__label .vl-checkbox__box::after {
  background-color: $vl-highlighted-action-color;
  color: $vl-highlighted-action-color;
  border-color: $vl-highlighted-action-color;
}
