.vl-header {
  .site-bar {
    &__lang {
      margin-right: 1rem;
      cursor: pointer;
      float: right;
      position: relative;
      top: 1rem;
      padding-top: 0;
      display: none;

      @media only screen and (min-width: $vl-bp-medLarge) {
        display: block;
      }

      .vl-vi-arrow-down-fat {
        color: $vl-highlighted-action-color;
      }

      .vl-popover__content {
        min-width: 6rem;
        width: 6rem;
        border-radius: 0.3rem;
        border: 1px solid $vl-ghost;
      }

      .vl-popover__link-list {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        li {
          ~ li {
            border-left: 1px solid $vl-ghost;
          }

          .vl-link {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }

    &__lang-mobile {
      display: none;
    }
  }
  &.mobileNavIsOpen {
    .site-bar {
      &__lang {
        display: none;
      }

      &__lang-mobile {
        font-weight: 600;
        display: inline-block;
        position: absolute;
        left: 3rem;
        top: 1rem;
        border: none;
        font-size: 1.6rem;

        li {
          display: inline-block;
          padding: 0.2rem 0.5rem;

          button {
            text-decoration: none;
          }
        }

        .vl-vi-arrow-right-fat {
          color: $vl-highlighted-action-color;
          margin-left: 0.5rem;
          vertical-align: middle;
        }

        @media only screen and (min-width: $vl-bp-medLarge) {
          display: none;
        }
      }
    }
  }
}
