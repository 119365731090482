.vl-button {
  border-radius: 0.2rem;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  /*white-space: normal;
  height: auto;
  min-height: 3.5rem;
  line-height: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;*/

  &--secondary {
    &.vl-button--large {
      line-height: 3.1rem;
    }
  }

  &--disabled {
    background-color: $vl-disabled-button-bg-color;
    border-color: $vl-disabled-button-bg-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $vl-disabled-button-bg-color;
    }

    &.vl-button--secondary {
      color: $vl-disabled-button-bg-color;

      &:hover,
      &:focus,
      &:active {
        background-color: white;
        border-color: $vl-disabled-button-bg-color;
        color: $vl-disabled-button-bg-color;
      }
    }
  }
}

.vl-action-group--collapse--s {
  [class^='vl-button'],
  [class*='vl-button'] {
    width: 100%;

    @include breakpoint(small) {
      width: auto;
    }
  }
}
