.vl-header {
  .site-bar {
    &__support {
      float: right;
      position: relative;
      top: 1rem;
      padding-top: 0;
      cursor: pointer;

      .vl-popover__content {
        border-radius: 0;
      }

      .vl-vi-question-mark-filled {
        color: $vl-highlighted-action-color;
      }

      .vl-title {
        color: $vl-black;
        margin-bottom: 0;
      }

      .vl-title--h4 {
        font-size: 1.8rem;
        font-weight: 600;
        padding: 0 1.5rem 1.5rem;
      }

      .vl-title--h5 {
        font-size: 1.6rem;
        font-weight: 600;
        padding: 1.5rem 1.5rem 0;
      }

      .vl-link {
        text-decoration: none;
      }

      .vl-icon-list {
        &__item {
          padding: 1.5rem 0.5rem 1.5rem 5rem;
          position: relative;
          margin-bottom: 0;
          line-height: 2.4rem;

          p {
            font-size: 1.4rem;
          }

          &:not(:last-child) {
            border-bottom: 0.1rem solid $vl-border-color;
          }

          .vl-icon {
            width: 2rem;
            height: 2rem;
            top: 1.8rem;
            left: 1.5rem;

            &::before {
              color: black;
            }
          }

          .vl-link {
            font-weight: 400;
            padding: 0;
          }
        }
      }

      .vl-popover__close-btn {
        top: 0.5rem;
        right: 1rem;
        font-size: 1.7rem;
      }

      .vl-popover__content {
        max-height: 50rem;
        min-width: 37rem;
        width: 37rem;
        border-radius: 0.3rem;
        border: 1px solid $vl-ghost;

        > div {
          padding: 2rem;
          overflow-y: auto;
          max-height: 50rem;
        }
      }
    }
  }
}
