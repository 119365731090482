@import '../../../../ui-flanders/themes/bl/scss/abstract/mixins';
@import '../../../../ui-flanders/themes/bl/scss/abstract/variables';

.vl-main-content {
  .rainbow {
    display: none;
    @media only screen and (min-width: $vl-bp-medLarge) {
      display: flex;
    }
  }
}
.vl-header {
  &.mobileNavIsOpen {
    .site-bar {
      .rainbow {
        display: flex;
        @media only screen and (min-width: $vl-bp-medLarge) {
          display: none;
        }
      }
    }
    .site-nav {
      height: auto;
      background-color: white;

      &__orderButton {
        padding: 1.5rem;

        button {
          background-color: $vl-accent-color;
          color: white;
        }
      }

      &__logo {
        display: none;
      }

      &__navigation {
        background-color: $vl-ghost-nav-bg;
        flex-direction: column-reverse;
        height: auto;
        margin-bottom: 2rem;

        ul {
          border: none;

          li {
            display: block;
            margin: 0 1.5rem;
            border-bottom: 0.1rem solid $vl-ghost-nav-border;

            &.support {
              display: block;

              .site-nav__subnavigation {
                li {
                  position: relative;
                  a {
                    padding-right: 4rem;
                    border: none;
                  }
                  .navItem-icon {
                    position: absolute;
                    right: -0.2rem;
                    transform: rotate(0deg);
                    top: 1rem;
                  }
                }
              }
            }
            &.logout {
              display: block;
              border: none;
              a {
                color: $vl-highlighted-action-color;
              }
            }

            .site-nav__subnavigation {
              padding-bottom: 1rem;
              a,
              li {
                border: none;
                margin: 0;
              }
            }

            a {
              border-bottom: none;
              text-align: left;
              width: 100%;
              padding: 1rem 0;
              color: $vl-ghost-nav-color;

              .navItem-icon {
                right: -0.2rem;
                top: 1.2rem;
              }
            }
          }
        }
      }
      ul.site-nav__subnavigation {
        padding-top: 0;
        padding-left: 1rem;
        position: relative;
      }
    }
  }
}
.site-nav {
  background-color: $vl-accent-color;
  height: 9rem;

  &__logo {
    height: 100%;
    max-height: 6rem;
    left: 4rem;
    position: absolute;
    top: 2rem;
    width: 12rem;

    // Only display the logo when we have the space
    @media only screen and (max-width: 1550px) {
      display: none;
    }

    img {
      height: 4rem;
    }
  }

  &__orderButton {
    padding-left: 2rem;
    button {
      background-color: white;
      color: $vl-accent-color;
    }
  }

  &__navigation {
    display: flex;
    position: relative;
    align-items: center;
    height: 9rem;

    ul {
      border-bottom: 1px solid rgba(226, 226, 226, 0.25);

      li {
        display: inline-block;
        text-align: center;

        &.logout,
        &.support {
          display: none;
        }

        &.active {
          .navItem-icon {
            transform: rotate(180deg);
          }
          a {
            border-bottom: 0.3rem solid white;
            font-weight: 600;
          }
          ul.site-nav__subnavigation {
            display: block;
            a {
              font-weight: 400;
            }
          }
        }

        a {
          transition: color 0.5s ease;
          position: relative;
          display: inline-block;
          color: white;
          font-weight: 400;
          padding: 1rem;
          text-decoration: none;
          text-align: center;
          border-bottom: 0.2rem solid $vl-accent-color;
          font-size: 1.4rem;

          .navItem-icon {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
            color: $vl-highlighted-action-color;
          }

          @include breakpoint(xsmall) {
            font-size: 1.8rem;
          }

          @media only screen and (min-width: $vl-bp-medLarge) {
            font-size: 1.6rem;
          }

          &:hover,
          &:focus {
            // font-weight: 400;
            color: $vl-highlighted-color;
          }

          &::after {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }
        }
      }
    }
    ul.site-nav__subnavigation {
      display: none;
      border: none;
      left: 0;
      position: absolute;
      li {
        a {
          border: none;
          color: white;
        }
        &.active {
          a {
            color: $vl-highlighted-color;
          }
        }
      }
    }
  }

  &__navigation-wrapper {
    align-self: flex-start;
    flex: 0 0 auto;
    margin: 0 auto;

    @media only screen and (min-width: $vl-bp-medLarge) {
      margin: 0;
    }
  }
}
