.rainbow {
  height: 0.46667em;
  display: flex;

  > [class*='rainbow'] {
    width: 16.66667%;
  }

  .rainbow__1 {
    background-color: #7ab800;
  }

  .rainbow__2 {
    background-color: #00cdb4;
  }

  .rainbow__3 {
    background-color: #2d87c3;
  }

  .rainbow__4 {
    background-color: #ff7814;
  }

  .rainbow__5 {
    background-color: #be1414;
  }

  .rainbow__6 {
    background-color: #c80078;
  }

  .rainbow__7 {
    background-color: #6e198c;
  }
}
