.onboarding {
  &-title {
    color: $vl-highlighted-action-color !important;
  }

  &-img-wrapper {
    display: flex;
    flex-grow: 1;
    background: radial-gradient(
      ellipse at center,
      $vl-highlighted-action-color 0%,
      darken($vl-highlighted-action-color, 3%) 100%
    ) !important;
  }
}
